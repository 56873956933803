// import { OrbitControls } from '@react-three/drei'
import Lights from './Lights.jsx'
import { Level } from './Level.jsx'
import { Physics } from "@react-three/rapier"
import Player from './Player.jsx'
import useGame from './stores/useGame.jsx'

export default function Experience() {
    const blocksCount = useGame((state) => state.blocksCount)
    const blocksSeed = useGame((state) => state.blocksSeed)

    return <>
        {/* <OrbitControls makeDefault /> */}

        <color args={ [ '#bdedfc']} attach="background" />

        <Physics
            // debug
        >
            <Lights />

            <Level count={blocksCount} seed={blocksSeed}/>

            <Player />
        </Physics>
    </>
}